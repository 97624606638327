<template>

  <iframe :src="webUrl+'/admin/price/discount-a'"></iframe>
<!-- <iframe :src=" webUrl +'/admin/price/discount-a'"></iframe>-->
</template>

<script>
export default {
  name : "price_a",
  emits : [ 'setMenuKey' ],
  setup(props, { emit }) {
    emit('setMenuKey', 'gj.jgcxa')

    return {
      "webUrl" : process.env.VUE_APP_DAILIAO_WEB_URL
    }
  }
}
</script>

<style scoped>

</style>